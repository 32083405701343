import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useActions } from "./overmind";
import { TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const PasswortAbfrage = () => {
  const actions = useActions();
  const style = styles();
  const { t } = useTranslation();
  const [passwort, setPasswort] = useState("");

  return (
    <div className={style.container}>
      <div className={style.passwortPanel}>
        <TextField
          className={style.passwort}
          margin="dense"
          id="passwort"
          label={t("Passwort")}
          type="password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPasswort(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              actions.login(passwort);
            }
          }}
          value={passwort}
          fullWidth
          required={true}
        />
      </div>
    </div>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    passwortPanel: {
      width: theme.spacing(30),
    },
    passwort: {
      width: theme.spacing(30),
      border: "1px solid grey",
      padding: theme.spacing(0.5),
      borderRadius: theme.spacing(1),
      background: "#fff",
    },
  })
);
