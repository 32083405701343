import { createStyles, makeStyles } from "@mui/styles";
import { Slider, Theme, Tooltip } from "@mui/material";
import mond from "./mond.png";
import { FC, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  groesse?: number;
  slider?: boolean;
  himmelFarbe?: string;
  datum?: Date;
}

interface StyleProps {
  winkel: number;
  groesse: number;
  mondSchatten: string;
  skalierung: number;
  helligkeit: number;
  himmelFarbe: string;
}

export const Mond: FC<Props> = ({
  groesse = 200,
  slider = false,
  himmelFarbe = "#000",
  datum = new Date(),
}) => {
  //https://codepen.io/shamir/pen/YGbbNX
  //https://dev.to/thormeier/use-your-i-moon-gination-lets-build-a-moon-phase-visualizer-with-css-and-js-aih
  const perigaeum = new Date("2022-08-02 07:52:00");
  const neuMond = new Date("2022-03-02 18:34:00");
  // const vollMond = new Date("2022-11-08 12:02:00");
  const mondUmlaufLaenge = 27.5546;
  const mondPhasenLaenge = 29.53059;

  const { t } = useTranslation();

  const mondNaehe = (): number => {
    const tageSeitPerigaeum =
      (datum.getTime() - perigaeum.getTime()) / 1000 / 60 / 60 / 24;
    return (tageSeitPerigaeum % mondUmlaufLaenge) / mondUmlaufLaenge;
  };
  const mondPhasenWinkel = (): number => {
    const tageSeitNeuMond =
      (datum.getTime() - neuMond.getTime()) / 1000 / 60 / 60 / 24;
    const phase = (tageSeitNeuMond % mondPhasenLaenge) / mondPhasenLaenge;
    return 360 - Math.floor(phase * 360);
  };

  const [winkel, setWinkel] = useState(mondPhasenWinkel());

  const mondNaeheProzent = mondNaehe();
  const mondGroesse = 1 - 0.14 * mondNaeheProzent;
  const mondHelligkeit = 1 - 0.3 * mondNaeheProzent;

  const handleChange = (event: Event, newValue: number | number[]) => {
    setWinkel(newValue as number);
  };

  const mondSchatten = (): string => {
    const winkelWert = Math.abs(180 - winkel) / 180;
    const mondSchattenGroesse = groesse - winkelWert * groesse;

    const mondSchattenPositionLinks = (winkel - 180) / groesse / 10;
    const mondSchattenPositionOben = 0;
    const mondSchattenVerschwommen = groesse / 2;
    const transparenz = (1 - winkelWert) / 4;
    const farbe = "rgba(255,255,230," + transparenz + ") ";

    return (
      farbe +
      mondSchattenPositionLinks +
      "px " +
      mondSchattenPositionOben +
      "px " +
      mondSchattenVerschwommen +
      "px " +
      mondSchattenGroesse +
      "px"
    );
  };

  const tooltip = () => {
    const mondStand = () => {
      if (winkel > 355 && winkel < 5) {
        return t("Neumond");
      }
      if (winkel < 85) {
        return t("Abnehmender Sichelmond");
      }
      if (winkel < 95) {
        return t("Abnehmender Halbmond");
      }
      if (winkel < 175) {
        return t("Abnehmender Dreiviertelmond");
      }
      if (winkel < 185) {
        return t("Vollmond");
      }
      if (winkel < 265) {
        return t("Zunehmender Dreiviertelmond");
      }
      if (winkel < 275) {
        return t("Zunehmender Halbmond");
      }
      return t("Zunehmender Sichelmond");
    };
    // const tageSeitNeumond = (): number => {
    //   const tageSeitNeuMond =
    //     (heute.getTime() - neuMond.getTime()) / 1000 / 60 / 60 / 24;
    //   return tageSeitNeuMond % mondPhasenLaenge;
    // };
    // const naechsterVollmond = (): Date => {
    //   vollMond.getTime();
    // };
    return (
      <div>
        <div>{mondStand()}</div>
      </div>
    );
  };

  const style = styles({
    winkel,
    groesse,
    mondSchatten: mondSchatten(),
    skalierung: mondGroesse,
    helligkeit: mondHelligkeit,
    himmelFarbe,
  });
  return (
    <div>
      <Tooltip title={tooltip()}>
        <div className={style.container}>
          <div className={style.mond}>
            <div
              className={clsx(style.seite, {
                [style.hell]: winkel < 180,
                [style.dunkel]: winkel > 180,
              })}
            />
            <div
              className={clsx(style.seite, {
                [style.dunkel]: winkel < 180,
                [style.hell]: winkel > 180,
              })}
            />
            <div className={style.trenner}>
              <div
                className={clsx(style.trennerHaelfte, style.trennerLinks, {
                  [style.dunkel]: winkel < 180,
                  [style.hell]: winkel > 180,
                })}
              />
              <div
                className={clsx(style.trennerHaelfte, style.trennerRechts, {
                  [style.dunkel]: winkel > 180,
                  [style.hell]: winkel < 180,
                })}
              />
              <div className={style.rueckseite}>
                <div
                  className={clsx(
                    style.rueckseiteHaelfte,
                    style.rueckseiteMondLinks,
                    {
                      [style.rueckseiteMondBild]: winkel > 180,
                    }
                  )}
                />
                <div
                  className={clsx(
                    style.rueckseiteHaelfte,
                    style.rueckseiteMondRechts,
                    {
                      [style.rueckseiteMondBild]: winkel < 180,
                    }
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
      {slider ? (
        <Slider
          className={style.slider}
          value={winkel}
          onChange={handleChange}
          min={0}
          max={360}
          step={1}
        />
      ) : null}
    </div>
  );
};

const styles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    slider: {
      marginTop: "200px",
    },
    container: {
      position: "relative",
      width: ({ groesse }) => groesse + "px",
      scale: ({ skalierung }) => skalierung,
      filter: ({ helligkeit }) => "brightness(" + helligkeit + ")",
    },
    mond: {
      border: "0px",
      borderRadius: "100%",
      width: ({ groesse }) => groesse + "px",
      height: ({ groesse }) => groesse + "px",
      display: "flex",
      overflow: "hidden",
      alignItems: "center",
      position: "absolute",
      backgroundImage: "url(" + mond + ")",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      boxShadow: ({ mondSchatten }) => mondSchatten,
    },
    seite: {
      width: "50%",
      height: "100%",
    },
    hell: {
      backgroundColor: "transparent",
    },
    dunkel: {
      backgroundColor: ({ himmelFarbe }) => himmelFarbe,
    },
    trenner: {
      border: "0px",
      top: 0,
      left: 0,
      width: ({ groesse }) => groesse + "px",
      height: ({ groesse }) => groesse + "px",
      position: "absolute",
      borderRadius: "100%",
      transformStyle: "preserve-3d",
      backfaceVisibility: "hidden",
      transform: ({ winkel }) => "rotate3d(0,1,0," + winkel + "deg)",
    },
    trennerHaelfte: {
      position: "absolute",
      top: 0,
      width: ({ groesse }) => groesse / 2 + "px",
      height: ({ groesse }) => groesse + "px",
    },
    trennerLinks: {
      left: 0,
      borderTopLeftRadius: ({ groesse }) => groesse / 2 + "px",
      borderBottomLeftRadius: ({ groesse }) => groesse / 2 + "px",
    },
    trennerRechts: {
      right: 0,
      borderTopRightRadius: ({ groesse }) => groesse / 2 + "px",
      borderBottomRightRadius: ({ groesse }) => groesse / 2 + "px",
    },
    rueckseite: {
      top: 0,
      left: 0,
      width: ({ groesse }) => groesse + "px",
      height: ({ groesse }) => groesse + "px",
      position: "absolute",
      borderRadius: "100%",
      transformStyle: "preserve-3d",
      backfaceVisibility: "hidden",
      transform: "rotateY(180deg)",
    },
    rueckseiteHaelfte: {
      top: 0,
      width: ({ groesse }) => groesse / 2 + "px",
      height: ({ groesse }) => groesse + "px",
      position: "absolute",
      transformStyle: "preserve-3d",
      backfaceVisibility: "hidden",
      backgroundColor: "transparent",
    },
    rueckseiteMondLinks: {
      left: 0,
      borderTopLeftRadius: "100%",
      borderBottomLeftRadius: "100%",
      backgroundPosition: "left",
      transform: ({ groesse, winkel }) =>
        "perspective(" + groesse + "px) rotateY(" + (winkel - 180) + "px)",
    },
    rueckseiteMondRechts: {
      right: 0,
      borderTopRightRadius: "100%",
      borderBottomRightRadius: "100%",
      backgroundPosition: "right",
      transform: ({ groesse, winkel }) =>
        "perspective(" + groesse + "px) rotateY(" + (180 - winkel) + "px)",
    },
    rueckseiteMondBild: {
      backgroundImage: "url(" + mond + ")",
      backgroundSize: ({ groesse }) => groesse + "px",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
    },
  })
);
