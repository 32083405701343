import Uebernachtung from "./Uebernachtung";
import uuid from "react-uuid";
import Adresse from "./Adresse";

export default interface Meldung {
  id: string;
  personen: string[];
  uebernachtungVorTreffen?: Uebernachtung;
  uebernachtungNachTreffen?: Adresse;
  spaetesteRueckmeldung?: Date;
  anmerkungen: string;
  angekommen?: Date;
}

export const erstelleNeueMeldung: () => Meldung = () => {
  return {
    id: uuid(),
    personen: [],
    uebernachtungVorTreffen: undefined,
    uebernachtungNachTreffen: undefined,
    spaetesteRueckmeldung: undefined,
    anmerkungen: "",
    angekommen: undefined,
  };
};
