import React from "react";
import { PasswortAbfrage } from "./PasswortAbfrage";
import { TreffenUebersicht } from "./TreffenUebersicht";
import { Nebel } from "./Nebel";
import { useActions, useAppState } from "./overmind";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Snackbar,
  Theme,
} from "@mui/material";

export const Hestia = () => {
  const state = useAppState();
  const actions = useActions();
  const style = styles();

  const fehler = state.fehler ?? "";

  return (
    <div>
      {state.passwort ? (
        <TreffenUebersicht />
      ) : (
        <div className={style.himmel}>
          <Nebel />
          <PasswortAbfrage />
        </div>
      )}
      <Snackbar
        open={!!fehler}
        autoHideDuration={5000}
        onClose={actions.entferneFehler}
      >
        <Alert onClose={actions.entferneFehler} severity="error">
          <div>{fehler}</div>
        </Alert>
      </Snackbar>
      <Backdrop open={state.laden}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    himmel: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "#001",
      // backgroundColor: '#bef'
    },
  })
);
