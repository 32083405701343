import React from "react";
import "./Nebel.css";

export const Nebel = () => {
  return (
    <div className="fogwrapper">
      <div id="sterne" />
      <div id="foglayer_01" className="fog">
        <div className="image01" />
        <div className="image02" />
      </div>
      <div id="foglayer_02" className="fog">
        <div className="image01" />
        <div className="image02" />
      </div>
      <div id="foglayer_03" className="fog">
        <div className="image01" />
        <div className="image02" />
      </div>
    </div>
  );
};
