import Meldung from "./Meldung";
import uuid from "react-uuid";

export enum TeilnehmerListe {
  Potzblitz = "Potzblitz",
  Imperia = "Imperia",
}

export const getPersonen = (teilnehmer: TeilnehmerListe): string[][] => {
  if (teilnehmer === TeilnehmerListe.Potzblitz) {
    return PersonenPotzblitz;
  }
  return PersonenImperia;
};

const PersonenPotzblitz: string[][] = [
  ["Rabe", "Cnejna"],
  ["UV", "Violetta"],
  ["Huginn", "Inara"],
  ["Sleipnir", "Epona"],
  ["Harcos", "Rica"],
  ["Heimdall", "Modiv"],
  ["Saxorior", "Ela"],
  ["Erato", "Waldläufer"],
  ["Eiche", "Elbelfe"],
  ["Munin", "Giant"],
  ["Hernes Son", "Rahanas"],
  ["Hӕlvard"],
];

const PersonenImperia: string[][] = [
  ["Naza", "Vale"],
  ["Artus", "Sirona"],
  ["Wilder Mann", "Yule"],
  ["Kuro", "Alva"],
  ["Pamina"],
  ["Nino"],
];

export const emailMapping: Record<string, string> = {
  Rabe: "rabe@rabenhain.net",
  Cnejna: "cnejna@gmx.de",
  UV: "UltraViolett@t-online.de",
  Naza: "naza4@arcor.de",
};

export const inReachMapping: Record<string, string> = {
  UV: "08dac6fa-c287-fba6-000d-3aa7f4110000",
  Naza: "08dac990-124d-4d21-000d-3aa7e41c0000",
  Huginn: "08dac3c7-2e5e-5997-000d-3aa7f4110000",
};

export const meldungsListe = (personen: string[][]): Meldung[] => {
  const alsMeldung: (person: string[]) => Meldung = (person) => {
    return {
      id: uuid(),
      personen: person,
      uebernachtungVorTreffen: undefined,
      uebernachtungNachTreffen: undefined,
      spaetesteRueckmeldung: undefined,
      anmerkungen: "",
      angekommen: undefined,
    };
  };
  return personen.map(alsMeldung);
};
