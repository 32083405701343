import React from "react";
import "./index.css";
import { Hestia } from "./Hestia";
import reportWebVitals from "./reportWebVitals";
import { createOvermind } from "overmind";
import { config } from "./overmind";
import { Provider } from "overmind-react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import deLocale from "date-fns/locale/de";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { deepPurple, purple } from "@mui/material/colors";
import { deDE } from "@mui/material/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { render } from "react-dom";

const overmind = createOvermind(config);

const theme = createTheme(
  {
    palette: {
      primary: deepPurple,
      secondary: purple,
      text: {
        primary: "#000",
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          size: "small",
        },
      },
    },
  },
  deDE
);

//Funzt net mit Overmind
// const rootDiv = document.getElementById("root");
// const root = createRoot(rootDiv!);

// root.render(
render(
  <React.StrictMode>
    <Provider value={overmind}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        loclaleAdapter={deLocale}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <Hestia />
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>,
  document.querySelector("#root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
