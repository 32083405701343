import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Treffen, { erstelleNeuesTreffen } from "./daten/Treffen";
import { TreffenEditor } from "./widgets/TreffenEditor";
import { useActions, useAppState } from "./overmind";
import { Cached } from "@mui/icons-material";
import { MeldungsTabelle } from "./MeldungsTabelle";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { format } from "date-fns";
import heimathafen from "./heimathafen-1920.jpg";
import clsx from "clsx";
import { Mond } from "./widgets/Mond";
import deLocale from "date-fns/locale/de";

export const TreffenUebersicht = () => {
  const style = styles();
  const { t } = useTranslation();

  const state = useAppState();
  const actions = useActions();

  const { selektiertesTreffen } = state;

  const [neu, setNeu] = useState(false);

  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => setAnimation(true), 500);
  }, []);

  const treffen = state.treffen;

  const treffenKnopf = (tre: Treffen): ReactElement => {
    const name =
      tre.ort +
      " (" +
      format(new Date(tre.datum), "dd.MM.yyyy", { locale: deLocale }) +
      ")";
    return (
      <Chip
        variant="outlined"
        onClick={() => {
          setNeu(false);
          actions.setSelektiertesTreffen({ ...tre });
        }}
        key={name}
        label={name}
      />
    );
  };

  const treffenListe = () => {
    if (treffen.length > 0) {
      const sort = (a: Treffen, b: Treffen): number => {
        if (a.datum > b.datum) {
          return 1;
        }
        if (b.datum > a.datum) {
          return -1;
        }
        return a.ort.localeCompare(b.ort, "de");
      };
      const liste: ReactNode[] = [...treffen].sort(sort).map(treffenKnopf);
      return (
        <div className={style.geplant}>
          <span>{t("Geplante Treffen: ")}</span> {liste}
        </div>
      );
    }

    return <div>{t("Keine Treffen geplant")}</div>;
  };

  return (
    <div className={style.hintergrund}>
      <div className={style.bild} />
      <div className={style.seite}>
        <div className={style.mond}>
          <Mond groesse={50} himmelFarbe="rgba(0,20,64,0.90)" />
        </div>
        <div className={clsx(style.header, { [style.anzeigen]: animation })}>
          <div className={style.banner}>
            <Button onClick={() => actions.updateTreffen()}>
              <Cached />
            </Button>
          </div>
          <div className={style.treffen}>
            <div className={style.treffenListe}>{treffenListe()}</div>
            {neu ? null : (
              <Button color="primary" onClick={() => setNeu(true)}>
                + {t("Neues Treffen")}
              </Button>
            )}
            {neu ? (
              <div>
                <TreffenEditor
                  treffen={erstelleNeuesTreffen()}
                  neu={neu}
                  onSpeichern={(treffen) => {
                    actions.addTreffen(treffen);
                    setNeu(false);
                  }}
                  onAbbrechen={() => setNeu(false)}
                  onLoeschen={actions.deleteTreffen}
                />
              </div>
            ) : null}
          </div>
        </div>
        <Card className={clsx(style.karte, { [style.anzeigen]: animation })}>
          <CardHeader
            title={
              <TreffenEditor
                treffen={selektiertesTreffen}
                neu={false}
                onSpeichern={actions.editTreffen}
                onAbbrechen={() => {}}
                onLoeschen={actions.deleteTreffen}
              />
            }
          ></CardHeader>
          <CardContent>
            <div>
              <MeldungsTabelle />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    hintergrund: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background:
        "linear-gradient(92deg, rgb(57, 75, 121) 0%, rgb(84, 103, 145) 100%)",
    },
    bild: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundImage: "url(" + heimathafen + ")",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      maskImage:
        "linear-gradient(to bottom, black 0px, black calc(100vw/1920*850), transparent calc(100vw/1920*986), transparent 100%)",
    },
    seite: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      overflowY: "auto",
    },
    mond: {
      position: "absolute",
      top: "49px",
      left: "95px",
    },
    header: {
      opacity: 0,
      transitionDelay: "1.5s",
    },
    anzeigen: {
      "&&": {
        opacity: 1,
        transitionTimingFunction: "ease-in-out",
        transitionProperty: "opacity",
        transitionDuration: "2s",
      },
    },
    banner: {
      display: "flex",
      justifyContent: "end",
    },
    geplant: {
      "&& > *": {
        marginLeft: theme.spacing(1),
      },
    },
    treffen: {
      marginTop: "150px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "rgba(255,255,255,.9)",
    },
    treffenListe: {
      display: "flex",
      alignItems: "center",
    },
    karte: {
      opacity: 0,
      margin: theme.spacing(2),
      "&&": {
        background: "rgba(255,255,255,0.9)",
        transitionDelay: "2.5s",
      },
    },
    add: {
      color: "#fff",
    },
  })
);
