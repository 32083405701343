import Meldung, { erstelleNeueMeldung } from "./daten/Meldung";
import { MeldungEditor } from "./widgets/MeldungEditor";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useActions, useAppState } from "./overmind";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const MeldungsTabelle = () => {
  const { t } = useTranslation();
  const style = styles();
  const state = useAppState();
  const actions = useActions();

  const [neueMeldung, setNeueMeldung] = useState<boolean>(false);

  const selektiertesTreffen = state.selektiertesTreffen;

  if (selektiertesTreffen) {
    const sort = (a: Meldung, b: Meldung): number => {
      if (a.angekommen) {
        if (b.angekommen) {
          return a.angekommen > b.angekommen ? 1 : -1;
        }
        return 1;
      }
      if (b.angekommen) {
        return -1;
      }
      if (!!a.spaetesteRueckmeldung && !!b.spaetesteRueckmeldung) {
        if (
          new Date(a.spaetesteRueckmeldung) > new Date(b.spaetesteRueckmeldung)
        ) {
          return 1;
        } else if (
          new Date(a.spaetesteRueckmeldung) < new Date(b.spaetesteRueckmeldung)
        ) {
          return -1;
        }
      }
      if (!!a.spaetesteRueckmeldung) {
        return 1;
      } else if (!!b.spaetesteRueckmeldung) {
        return -1;
      }
      return [...a.personen]
        .sort()[0]
        .localeCompare([...b.personen].sort()[0], "de");
    };
    const meldungen = [...selektiertesTreffen.meldungen]
      .sort(sort)
      .map((meldung) => (
        <MeldungEditor
          key={meldung.id}
          meldung={meldung}
          neu={false}
          onSpeichern={actions.editMeldung}
          onAbbrechen={() => {}}
          treffenDatum={selektiertesTreffen?.datum}
        />
      ));

    return (
      <Table className={style.meldungen}>
        <TableHead>
          <TableRow>
            <TableCell>{t("Personen")}</TableCell>
            <TableCell>{t("Übernachtung vorher")}</TableCell>
            <TableCell>{t("Angekommen")}</TableCell>
            <TableCell>{t("Übernachtung danach")}</TableCell>
            <TableCell>{t("Voraussichtliche späteste Heimkehr")}</TableCell>
            <TableCell>{t("Anmerkungen")}</TableCell>
            <TableCell>{t("Angekommen")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meldungen.length > 0 ? (
            meldungen
          ) : (
            <TableRow>
              <TableCell colSpan={7}>
                {t("Keine Meldungen vorhanden")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          {neueMeldung ? (
            <MeldungEditor
              meldung={erstelleNeueMeldung()}
              neu={true}
              onSpeichern={(meldung) => {
                actions.addMeldung(meldung);
                setNeueMeldung(false);
              }}
              onAbbrechen={() => setNeueMeldung(false)}
              treffenDatum={selektiertesTreffen.datum}
            />
          ) : (
            <TableRow>
              <TableCell colSpan={7} className={style.neueMeldung}>
                <Button onClick={() => setNeueMeldung(true)}>
                  + {t("Eintrag hinzufügen")}
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    );
  }

  return <div>{t("Kein Treffen ausgewählt")}</div>;
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    meldungen: {
      borderCollapse: "collapse",
      width: "100%",
      tableLayout: "fixed",
      "& th": {
        fontWeight: "bold",
        textAlign: "center",
        borderBottom: "1px solid grey",
      },
      "& tr:nth-child(odd)": {},
      "& tr:nth-child(even)": {},
      "& td": {
        width: "25%",
        padding: theme.spacing(1),
        textAlign: "center",
        borderRight: "1px solid " + theme.palette.grey,
      },
      "& td:last-child": {
        borderRight: "none",
      },
      "& tbody > tr:hover": {
        // backgroundColor: "#eee",
      },
    },
    neueMeldung: {
      textAlign: "left",
    },
  })
);
