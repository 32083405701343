import Adresse from "../daten/Adresse";
import React, { FC, useEffect, useState } from "react";
import { Button, MenuItem, TextField, Theme, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add, Done } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";

interface Props {
  adresse?: Adresse;
  onChange: (adresse?: Adresse) => void;
  alleAdressen: Adresse[];
}

export const AdresseEditor: FC<Props> = ({
  adresse,
  onChange,
  alleAdressen,
}) => {
  const { t } = useTranslation();
  const style = styles();

  const [name, setName] = useState<string>(adresse?.name ?? "");
  const [ort, setOrt] = useState<string>(adresse?.ort ?? "");
  const [plz, setPlz] = useState<string>(adresse?.plz ?? "");
  const [strasse, setStrasse] = useState<string>(adresse?.strasse ?? "");
  const [hausnummer, setHausnummer] = useState<string>(
    adresse?.hausnummer ?? ""
  );

  useEffect(() => {
    const adresse: Adresse | undefined =
      name.trim() ||
      ort.trim() ||
      plz.trim() ||
      strasse.trim() ||
      hausnummer.trim()
        ? {
            name,
            ort,
            plz,
            strasse,
            hausnummer,
          }
        : undefined;
    onChange(adresse);
  }, [name, ort, plz, strasse, hausnummer, onChange]);
  const adresseLang = (adr: Adresse | undefined): string => {
    if (!adr) {
      return t("zu Hause");
    }
    let wert = adr.name ?? "";
    if (adr.strasse) {
      if (wert) {
        wert += ", ";
      }
      wert += adr.strasse;
      if (adr.hausnummer) {
        wert += " " + adr.hausnummer;
      }
    }
    if (adr.ort || adr.plz) {
      if (wert) {
        wert += ", in ";
      }
      if (adr.plz) {
        wert += adr.plz;
        if (adr.ort) {
          wert += " ";
        }
      }
      if (adr.ort) {
        wert += adr.ort;
      }
    }
    return wert;
  };

  const adressWert = React.useMemo(() => adresseLang(adresse), [adresse]);

  const [bearbeitung, setBearbeitung] = useState<boolean>(false);

  if (!bearbeitung) {
    return (
      <Tooltip title={adressWert} placement={"top"}>
        <TextField
          select
          className={clsx({ [style.leer]: adresse === undefined })}
          fullWidth={true}
          label={t("Adresse")}
          value={adresse?.name ?? "zuhause"}
        >
          <MenuItem
            value="zuhause"
            onClick={() => {
              setName("");
              setPlz("");
              setOrt("");
              setStrasse("");
              setHausnummer("");
              setBearbeitung(false);
            }}
          >
            {adresseLang(undefined)}
          </MenuItem>
          <hr />
          <MenuItem
            value={t("Neue Unterkunft...")}
            onClick={() => setBearbeitung(true)}
          >
            <Add /> {t("Neue Unterkunft...")}
          </MenuItem>
          <hr />
          {alleAdressen.map((adr) => {
            return (
              <MenuItem
                value={adr.name}
                onClick={() => {
                  setName(adr.name);
                  setOrt(adr?.ort ?? "");
                  setPlz(adr?.plz ?? "");
                  setStrasse(adr?.strasse ?? "");
                  setHausnummer(adr?.hausnummer ?? "");
                  setBearbeitung(true);
                }}
              >
                {adresseLang(adr)}
              </MenuItem>
            );
          })}
        </TextField>
      </Tooltip>
    );
  }

  return (
    <div className={style.liste}>
      <TextField
        label={t("Name")}
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <TextField
        label={t("Ort")}
        value={ort}
        onChange={(event) => setOrt(event.target.value)}
      />
      <TextField
        label={t("PLZ")}
        value={plz}
        onChange={(event) => setPlz(event.target.value)}
      />
      <TextField
        label={t("Straße")}
        value={strasse}
        onChange={(event) => setStrasse(event.target.value)}
      />
      <TextField
        label={t("Hausnummer")}
        value={hausnummer}
        onChange={(event) => setHausnummer(event.target.value)}
      />
      <Button onClick={() => setBearbeitung(false)}>
        <Done />
      </Button>
    </div>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    liste: {
      "&& > *": {
        marginBottom: theme.spacing(1),
      },
    },
    leer: {
      "&& div": {
        color: theme.palette.grey["600"],
      },
    },
  })
);
