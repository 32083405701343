import { FC, useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Chip,
  TextField,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { isEqual } from "lodash";

interface Props {
  wert: string[];
  onChange: (werte: string[]) => void;
  label: string;
  modus?:
    | "none"
    | "text"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search"
    | undefined;
  vorschlaege?: Record<string, Record<string, string>>;
}

export const MultiTextField: FC<Props> = ({
  wert,
  onChange,
  label,
  modus,
  vorschlaege,
}) => {
  const [aktuell, setAktuell] = useState<string[]>(wert);
  const [neuerWert, setNeuerWert] = useState<string>("");

  const style = styles();

  const chipLabel = (aktuellerWert: string): string => {
    if (vorschlaege) {
      for (const prefix in vorschlaege) {
        const record = vorschlaege[prefix];
        if (record) {
          for (const name in record) {
            const id = record[name];
            if (id === aktuellerWert) {
              return name + " (" + prefix + ")";
            }
          }
        }
      }
    }
    return aktuellerWert;
  };

  const werteKarten = aktuell.map((akt) => (
    <Chip
      key={akt}
      label={chipLabel(akt)}
      onDelete={() => {
        const neuesAktuell = aktuell.filter((a) => !isEqual(a, akt));
        setAktuell(neuesAktuell);
        onChange(neuesAktuell);
      }}
      variant="outlined"
    />
  ));

  const wertHinzufuegen = (neu: string | undefined) => {
    if (neu && neu.trim()) {
      const neuesAktuell = [...aktuell, neu];

      setAktuell(neuesAktuell);
      onChange(neuesAktuell);
      setNeuerWert("");
    }
  };

  const optionen = vorschlaege
    ? Object.entries(vorschlaege).flatMap(([prefix, record]) =>
        Object.entries(record)
          .filter(([name, id]) => !aktuell.includes(id))
          .map(([name, id]) => ({
            label: name + " (" + prefix + ")",
            id,
          }))
      )
    : [];

  const renderTextField = (params?: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      label={label}
      value={neuerWert}
      inputMode={modus}
      onChange={(event) => setNeuerWert(event.target.value)}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          wertHinzufuegen(neuerWert);
        }
      }}
      onBlur={(event) => wertHinzufuegen(neuerWert)}
    />
  );

  const renderInput = () => {
    if (vorschlaege) {
      return (
        <Autocomplete
          className={style.select}
          freeSolo
          options={optionen}
          blurOnSelect
          clearOnBlur
          value={neuerWert}
          inputMode={modus}
          onChange={(event, value, reason) => {
            if (value && typeof value === "object") {
              if (reason === "selectOption") {
                wertHinzufuegen(value.id);
              }
            }
          }}
          onBlur={(event) => wertHinzufuegen(neuerWert)}
          renderInput={renderTextField}
        />
      );
    }
    return renderTextField();
  };
  return (
    <div className={style.container}>
      {renderInput()}
      <div className={style.werte}>{werteKarten}</div>
    </div>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
    },
    werte: {
      marginLeft: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
    },
    select: {
      width: 200,
    },
  })
);
