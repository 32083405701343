import { Context } from "./index";
import Treffen from "../daten/Treffen";
import {
  addMeldungRequest,
  addTreffenRequest,
  ankunftRequest,
  deleteTreffenRequest,
  editMeldungRequest,
  editTreffenRequest,
  getTreffenRequest,
} from "../services/TreffenService";
import { Ort } from "../daten/Ort";
import Meldung from "../daten/Meldung";

export const login = async ({ state, actions }: Context, passwort: string) => {
  actions.setLaden(true);
  getTreffenRequest(
    passwort,
    (treffen) => {
      actions.setTreffen(treffen);
      actions.setPasswort(passwort);
      actions.setLaden(false);
      if (treffen.length === 1) {
        actions.setSelektiertesTreffen(treffen[0]);
      }
    },
    actions.zeigeFehler
  );
};

export const setSelektiertesTreffen = (
  { state }: Context,
  treffen?: Treffen
) => {
  state.selektiertesTreffen = treffen;
};

export const updateTreffen = async ({ state, actions }: Context) => {
  const id = state.selektiertesTreffen?.id;
  actions.setLaden(true);
  getTreffenRequest(
    state.passwort,
    (treffen) => {
      actions.setTreffen([]);
      actions.setTreffen(treffen);
      actions.setSelektiertesTreffen(undefined);
      if (id === undefined && treffen.length === 1) {
        actions.setSelektiertesTreffen(treffen[0]);
      } else {
        const t = treffen.find((treffen) => treffen.id === id);
        if (t) {
          actions.setSelektiertesTreffen(t);
        }
      }
      actions.setLaden(false);
    },
    actions.zeigeFehler
  );
};

export const zeigeFehler = ({ state }: Context, fehler: any) => {
  state.laden = false;
  state.fehler = fehler.error ?? String(fehler);
};

export const entferneFehler = ({ state }: Context) => {
  state.fehler = undefined;
};

export const setPasswort = ({ state }: Context, passwort: string) => {
  state.passwort = passwort;
};

export const setLaden = ({ state }: Context, laden: boolean) => {
  state.laden = laden;
};

export const setTreffen = ({ state }: Context, treffen: Treffen[]) => {
  state.treffen = treffen;
};

export const addTreffen = async (
  { state, actions }: Context,
  treffen: Treffen
) => {
  actions.setLaden(true);
  addTreffenRequest(
    state.passwort,
    treffen,
    actions.updateTreffen,
    actions.zeigeFehler
  );
};

export const editTreffen = async (
  { state, actions }: Context,
  treffen: Treffen
) => {
  actions.setLaden(true);
  editTreffenRequest(
    state.passwort,
    treffen,
    actions.updateTreffen,
    actions.zeigeFehler
  );
};

export const addMeldung = async (
  { state, actions }: Context,
  meldung: Meldung
) => {
  if (state.selektiertesTreffen) {
    actions.setLaden(true);
    addMeldungRequest(
      state.passwort,
      state.selektiertesTreffen.id,
      meldung,
      actions.updateTreffen,
      actions.zeigeFehler
    );
  }
};
export const editMeldung = async (
  { state, actions }: Context,
  meldung: Meldung
) => {
  if (state.selektiertesTreffen) {
    actions.setLaden(true);
    editMeldungRequest(
      state.passwort,
      state.selektiertesTreffen.id,
      meldung,
      actions.updateTreffen,
      actions.zeigeFehler
    );
  }
};

export const deleteTreffen = async ({ state, actions }: Context) => {
  if (state.selektiertesTreffen) {
    actions.setLaden(true);
    deleteTreffenRequest(
      state.passwort,
      state.selektiertesTreffen.id,
      actions.updateTreffen,
      actions.zeigeFehler
    );
  }
};

export const ankunft = async (
  { state, actions }: Context,
  {
    treffenId,
    meldungId,
    ort,
  }: { treffenId: string; meldungId: string; ort: Ort }
) => {
  actions.setLaden(true);
  ankunftRequest(
    state.passwort,
    treffenId,
    meldungId,
    ort,
    actions.updateTreffen,
    actions.zeigeFehler
  );
};
