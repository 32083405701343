import React, { FC, useState } from "react";
import { Ort } from "../daten/Ort";
import { Close, Done } from "@mui/icons-material";
import { format } from "date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useActions } from "../overmind";
import deLocale from "date-fns/locale/de";

interface Props {
  ort: Ort;
  personen: string[];
  treffenId: string;
  meldungId: string;
  angekommen?: Date;
}

export const AnkunftKnopf: FC<Props> = ({
  ort,
  personen,
  treffenId,
  meldungId,
  angekommen,
}) => {
  const { t } = useTranslation();
  const actions = useActions();
  const [angekommenOffen, setAngekommenOffen] = useState<boolean>(false);

  if (angekommen) {
    return (
      <div>
        <Done />{" "}
        {format(new Date(angekommen), "'Am' dd.MM. 'um' HH:mm", {
          locale: deLocale,
        })}
      </div>
    );
  }

  const bestaetigung =
    ort === "zuHause" ? t("Zuhause angekommen?") : t("Zwischenziel erreicht?");
  return (
    <>
      <Dialog
        open={angekommenOffen}
        maxWidth={"xs"}
        onClose={() => setAngekommenOffen(false)}
      >
        <DialogTitle>{bestaetigung}</DialogTitle>
        <DialogContent>{personen.join(", ")}</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              actions.ankunft({
                treffenId: treffenId,
                meldungId: meldungId,
                ort: ort,
              });
              setAngekommenOffen(false);
            }}
          >
            <Done />
          </Button>
          <Button color="secondary" onClick={() => setAngekommenOffen(false)}>
            <Close />
          </Button>
        </DialogActions>
      </Dialog>
      <Button color="primary" onClick={() => setAngekommenOffen(true)}>
        {t("Angekommen?")}
      </Button>
    </>
  );
};
