import Treffen from "../daten/Treffen";
import Meldung from "../daten/Meldung";

export type State = {
  passwort: string;
  selektiertesTreffen?: Treffen;
  selektierteMeldung?: Meldung;
  treffen: Treffen[];
  fehler?: string;
  laden: boolean;
};

export const state: State = {
  passwort: "",
  selektiertesTreffen: undefined,
  selektierteMeldung: undefined,
  treffen: [],
  fehler: undefined,
  laden: false,
};
