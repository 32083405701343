import Meldung from "../daten/Meldung";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { format } from "date-fns";
import { AccessTime, Close, Done } from "@mui/icons-material";
import Uebernachtung from "../daten/Uebernachtung";
import { useAppState } from "../overmind";
import { useTranslation } from "react-i18next";
import { MultiTextField } from "./MultiTextField";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Tooltip,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from "date-fns/locale/de";
import { AdresseEditor } from "./AdresseEditor";
import Adresse from "../daten/Adresse";
import { AnkunftKnopf } from "./AnkunftKnopf";
import clsx from "clsx";
import { isEqual } from "lodash";

interface Props {
  meldung: Meldung;
  neu: boolean;
  onSpeichern: (meldung: Meldung) => void;
  onAbbrechen: () => void;
  treffenDatum: Date;
}

export const MeldungEditor: FC<Props> = ({
  meldung,
  neu,
  onSpeichern,
  onAbbrechen,
  treffenDatum,
}) => {
  const { t } = useTranslation();
  const style = styles();
  const state = useAppState();

  const [bearbeitung, setBearbeitung] = useState<boolean>(neu);

  const [personen, setPersonen] = useState<string[]>([]);
  const [uebernachtungVorTreffen, setUebernachtungVorTreffen] = useState<
    Uebernachtung | undefined
  >();
  const [uebernachtungNachTreffen, setUebernachtungNachTreffen] = useState<
    Adresse | undefined
  >();
  const [spaetesteRueckmeldung, setSpaetesteRueckmeldung] = useState<
    Date | undefined
  >(new Date());
  const [anmerkungen, setAnmerkungen] = useState<string>("");

  useEffect(() => {
    setPersonen(meldung.personen);
    setUebernachtungVorTreffen(meldung.uebernachtungVorTreffen);
    setUebernachtungNachTreffen(meldung.uebernachtungNachTreffen);
    setSpaetesteRueckmeldung(meldung.spaetesteRueckmeldung);
    setAnmerkungen(meldung.anmerkungen);
  }, [meldung]);

  const gefilterteAdressen: Adresse[] = React.useMemo(() => {
    const gespeicherteAdressen: (Adresse | undefined)[] = state.treffen
      .map((treffen) =>
        treffen.meldungen
          .map((m) => [
            m.uebernachtungVorTreffen?.adresse,
            m.uebernachtungNachTreffen,
          ])
          .flat(2)
      )
      .flat(1);

    const alleAdressen = [
      ...gespeicherteAdressen,
      uebernachtungVorTreffen?.adresse,
      uebernachtungNachTreffen,
    ];

    const gefiltert: Adresse[] = [];
    alleAdressen.forEach((adr) => {
      if (adr && !gefiltert.some((a) => isEqual(a, adr))) {
        gefiltert.push(adr);
      }
    });
    return gefiltert;
  }, [uebernachtungVorTreffen, uebernachtungNachTreffen, state.treffen]);

  const selektiertesTreffen = state.selektiertesTreffen;
  if (!selektiertesTreffen) {
    return null;
  }

  // ==================EDITOR============================

  if (bearbeitung) {
    const namenEditieren = (
      <MultiTextField
        wert={personen}
        onChange={setPersonen}
        label={t("Personen")}
      />
    );
    const rueckmeldungEditieren = () => {
      return (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={deLocale}
        >
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label={t("Vorraussichtliche Rückkehr")}
            minDateTime={selektiertesTreffen.datum}
            ampm={false}
            value={
              spaetesteRueckmeldung
                ? new Date(spaetesteRueckmeldung)
                : new Date(treffenDatum)
            }
            onChange={(date) => {
              if (date) {
                setSpaetesteRueckmeldung(date);
              }
            }}
          />
        </LocalizationProvider>
      );
    };

    const anmerkungenEditieren = () => {
      return (
        <TextField
          label={t("Anmerkungen")}
          value={anmerkungen}
          onChange={(event) => setAnmerkungen(event.target.value)}
          multiline
        />
      );
    };

    return (
      <TableRow>
        <TableCell>{namenEditieren}</TableCell>
        <TableCell>
          <AdresseEditor
            adresse={uebernachtungVorTreffen?.adresse}
            onChange={(adresse) => {
              const uebernachtung: Uebernachtung | undefined = adresse
                ? {
                    adresse,
                    ankunftVorZehn:
                      uebernachtungVorTreffen?.ankunftVorZehn ?? false,
                  }
                : undefined;
              if (!isEqual(uebernachtungVorTreffen?.adresse, adresse)) {
                setUebernachtungVorTreffen(uebernachtung);
              }
            }}
            alleAdressen={gefilterteAdressen}
          />
        </TableCell>
        <TableCell>
          {uebernachtungVorTreffen && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={uebernachtungVorTreffen.ankunftVorZehn}
                    onChange={(event) => {
                      setUebernachtungVorTreffen({
                        ...uebernachtungVorTreffen,
                        ankunftVorZehn: event.target.checked,
                      });
                    }}
                  />
                }
                label={t("Ankunft vor 22 Uhr")}
              />
            </FormGroup>
          )}
        </TableCell>
        <TableCell>
          <AdresseEditor
            adresse={uebernachtungNachTreffen}
            onChange={setUebernachtungNachTreffen}
            alleAdressen={gefilterteAdressen}
          />
        </TableCell>
        <TableCell>{rueckmeldungEditieren()}</TableCell>
        <TableCell>{anmerkungenEditieren()}</TableCell>
        <TableCell>
          {t("Änderungen übernehmen?")}
          <div>
            <Button
              color="primary"
              onClick={() => {
                const editMeldung: Meldung = {
                  id: meldung.id,
                  personen,
                  uebernachtungVorTreffen,
                  uebernachtungNachTreffen,
                  spaetesteRueckmeldung,
                  anmerkungen,
                  angekommen: meldung.angekommen,
                };
                onSpeichern(editMeldung);
                setBearbeitung(false);
              }}
            >
              <Done />
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                onAbbrechen();
                setBearbeitung(false);
              }}
            >
              <Close />
            </Button>
          </div>
        </TableCell>
      </TableRow>
    );
  }
  // =============================TABELLE=========================================

  const uebernachtung = (uebernachtung?: Uebernachtung): ReactNode => {
    if (uebernachtung) {
      return adresse(uebernachtung.ankunftVorZehn, uebernachtung.adresse);
    }
    return adresse(false, undefined);
  };

  const adresse = (zeigeUhr: boolean, adresse?: Adresse): ReactNode => {
    if (adresse) {
      return (
        <div className={style.adresse}>
          {zeigeUhr && (
            <Tooltip title={t("Ankunft vor 22 Uhr")} placement={"top"}>
              <AccessTime fontSize="small" className={style.uhr} />
            </Tooltip>
          )}
          <div className={style.name}>{adresse.name}</div>
          <div className={style.strasse}>
            {adresse.strasse} {adresse.hausnummer}
          </div>
          <div className={style.ort}>
            {adresse.plz} {adresse.ort}
          </div>
        </div>
      );
    }

    return <div className={clsx(style.name, style.leer)}>{t("zu Hause")}</div>;
  };

  const namen = meldung.personen.join(", ");
  const rueckmeldung = meldung.spaetesteRueckmeldung
    ? format(
        new Date(meldung.spaetesteRueckmeldung),
        "'Am' dd.MM. 'um' HH:mm",
        { locale: deLocale }
      )
    : "-- : --";

  const jetzt = new Date();
  const className = () => {
    if (meldung.angekommen) {
      return style.angekommen;
    }
    if (!meldung.spaetesteRueckmeldung) {
      return style.keineZeit;
    } else if (jetzt > new Date(meldung.spaetesteRueckmeldung)) {
      return style.verspaetet;
    }
    const imHotel = Boolean(meldung.uebernachtungVorTreffen?.angekommen);
    if (imHotel) {
      return style.imHotel;
    }
    const zehnUhr = new Date(treffenDatum);
    zehnUhr.setDate(zehnUhr.getDate() - 1);
    zehnUhr.setHours(22);
    if (
      !imHotel &&
      meldung.uebernachtungVorTreffen?.ankunftVorZehn &&
      jetzt > zehnUhr
    ) {
      return style.verspaetet;
    }
    return "";
  };
  return (
    <TableRow className={clsx(className(), style.zeile)}>
      <TableCell onClick={() => setBearbeitung(true)}>{namen}</TableCell>
      <TableCell onClick={() => setBearbeitung(true)}>
        {uebernachtung(meldung.uebernachtungVorTreffen)}
      </TableCell>
      <TableCell>
        {meldung.uebernachtungVorTreffen ? (
          <AnkunftKnopf
            ort="vorTreffen"
            personen={meldung.personen}
            treffenId={selektiertesTreffen.id}
            meldungId={meldung.id}
            angekommen={meldung.uebernachtungVorTreffen.angekommen}
          />
        ) : null}
      </TableCell>
      <TableCell onClick={() => setBearbeitung(true)}>
        {adresse(false, meldung.uebernachtungNachTreffen)}
      </TableCell>
      <TableCell onClick={() => setBearbeitung(true)}>{rueckmeldung}</TableCell>
      <TableCell onClick={() => setBearbeitung(true)}>
        {meldung.anmerkungen}
      </TableCell>
      <TableCell>
        <AnkunftKnopf
          ort="zuHause"
          personen={meldung.personen}
          treffenId={selektiertesTreffen.id}
          meldungId={meldung.id}
          angekommen={meldung.angekommen}
        />
      </TableCell>
    </TableRow>
  );
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    zeile: {
      "&:hover": {
        backgroundColor: "#eee",
      },
    },
    edit: {
      position: "absolute",
      left: 0,
    },
    adresse: {
      position: "relative",
    },
    name: {
      fontWeight: "bold",
      fontSize: "small",
    },
    uhr: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: 0,
    },
    strasse: {
      fontSize: "smaller",
    },
    ort: {
      fontSize: "smaller",
    },
    imHotel: {
      // backgroundColor: "#ddf",
    },
    keineZeit: {
      backgroundColor: "#e1e1e1",
    },
    angekommen: {
      backgroundColor: "#feb",
    },
    verspaetet: {
      backgroundColor: "#fdd",
    },
    leer: {
      color: theme.palette.grey["600"],
    },
  })
);
