import Meldung from "./Meldung";
import uuid from "react-uuid";

export default interface Treffen {
  id: string;
  ort: string;
  datum: Date;
  meldeTelefon: string;
  teilnehmer: string[];
  meldungen: Meldung[];
  benachrichtigungsAdressen: string[];
}

export const erstelleNeuesTreffen: () => Treffen = () => {
  return {
    id: uuid(),
    ort: "",
    datum: new Date(),
    meldeTelefon: "",
    teilnehmer: [],
    meldungen: [],
    benachrichtigungsAdressen: [],
  };
};
