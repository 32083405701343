import { encode } from "base-64";
import Treffen from "../daten/Treffen";
import { Ort } from "../daten/Ort";
import Meldung from "../daten/Meldung";

const prod = {
  hestiaUrl: "/",
};
const dev = {
  hestiaUrl: "http://localhost:8080/",
};

const hestiaUrl = process.env.NODE_ENV === "development" ? dev : prod;

const config = {
  ...hestiaUrl,
  hestiaNutzer: "hestia",
};

const header = (passwort: string) => {
  return {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: "Basic " + encode(config.hestiaNutzer + ":" + passwort),
  };
};

const handled = (
  fetch: Promise<any>,
  onSuccess: (result: Response) => void,
  onError: (error: any) => void
) => {
  return fetch
    .then((result) => {
      if (result.ok) {
        onSuccess(result);
      } else {
        if (result.status === 401) {
          onError("Falsches Passwort");
        } else {
          result.text().then(onError);
        }
      }
    })
    .catch(onError);
};

const treffenAnpassen = (treffen: Treffen): Treffen => ({
  ...treffen,
  meldungen: treffen.meldungen.map(meldungAnpassen),
});
const meldungAnpassen = (meldung: Meldung): Meldung => ({
  ...meldung,
  spaetesteRueckmeldung: meldung.spaetesteRueckmeldung
    ? mitZeitZone(meldung.spaetesteRueckmeldung)
    : undefined,
});

const mitZeitZone = (date: Date): Date | undefined => {
  const neuesDatum = new Date(date);
  if (neuesDatum.getTime() === 0) {
    return undefined;
  }
  const zeitZonenAbweichung = -neuesDatum.getTimezoneOffset() * 60 * 1000;
  neuesDatum.setTime(neuesDatum.getTime() + zeitZonenAbweichung);

  return neuesDatum;
};

export const getTreffenRequest = async (
  passwort: string,
  setTreffen: (treffen: Treffen[]) => void,
  onError: (error: any) => void
): Promise<void> => {
  return handled(
    fetch(config.hestiaUrl + "treffen/get", {
      method: "get",
      headers: header(passwort),
    }),
    (res) =>
      res
        .json()
        .then((res) => setTreffen(res as Treffen[]))
        .catch(onError),
    onError
  );
};

export const addTreffenRequest = async (
  passwort: string,
  treffen: Treffen,
  onSuccess: () => void,
  onError: (error: any) => void
): Promise<void> => {
  return handled(
    fetch(config.hestiaUrl + "treffen/add", {
      method: "post",
      headers: header(passwort),
      body: JSON.stringify(treffenAnpassen(treffen)),
    }),
    onSuccess,
    onError
  );
};

export const editTreffenRequest = async (
  passwort: string,
  treffen: Treffen,
  onSuccess: () => void,
  onError: (error: any) => void
): Promise<void> => {
  return handled(
    fetch(config.hestiaUrl + "treffen/edit/" + treffen.id, {
      method: "put",
      headers: header(passwort),
      body: JSON.stringify(treffenAnpassen(treffen)),
    }),
    onSuccess,
    onError
  );
};
export const addMeldungRequest = async (
  passwort: string,
  treffenId: string,
  meldung: Meldung,
  onSuccess: () => void,
  onError: (error: any) => void
): Promise<void> => {
  return handled(
    fetch(config.hestiaUrl + "treffen/add/" + treffenId, {
      method: "post",
      headers: header(passwort),
      body: JSON.stringify(meldungAnpassen(meldung)),
    }),
    onSuccess,
    onError
  );
};
export const editMeldungRequest = async (
  passwort: string,
  treffenId: string,
  meldung: Meldung,
  onSuccess: () => void,
  onError: (error: any) => void
): Promise<void> => {
  return handled(
    fetch(config.hestiaUrl + "treffen/edit/" + treffenId + "/" + meldung.id, {
      method: "put",
      headers: header(passwort),
      body: JSON.stringify(meldungAnpassen(meldung)),
    }),
    onSuccess,
    onError
  );
};
export const deleteTreffenRequest = async (
  passwort: string,
  id: string,
  onSuccess: () => void,
  onError: (error: any) => void
): Promise<void> => {
  return handled(
    fetch(config.hestiaUrl + "treffen/delete/" + id, {
      method: "delete",
      headers: header(passwort),
    }),
    onSuccess,
    onError
  );
};

export const ankunftRequest = async (
  passwort: string,
  treffenId: string,
  meldungId: string,
  ort: Ort,
  onSuccess: () => void,
  onError: (error: any) => void
): Promise<void> => {
  return handled(
    fetch(
      config.hestiaUrl +
        "treffen/ankunft/" +
        treffenId +
        "/" +
        meldungId +
        "/" +
        ort,
      {
        method: "put",
        headers: header(passwort),
      }
    ),
    onSuccess,
    onError
  );
};
