import Treffen from "../daten/Treffen";
import { useTranslation } from "react-i18next";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Theme,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { Close, DeleteForever, Done, Edit } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from "date-fns/locale/de";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { MultiTextField } from "./MultiTextField";
import { format } from "date-fns";
import {
  emailMapping,
  getPersonen,
  inReachMapping,
  meldungsListe,
  TeilnehmerListe,
} from "../daten/TeilnehmerListe";

interface Props {
  treffen?: Treffen;
  neu: boolean;
  onSpeichern: (treffen: Treffen) => void;
  onAbbrechen: () => void;
  onLoeschen: () => void;
}

export const TreffenEditor: FC<Props> = ({
  treffen,
  neu,
  onSpeichern,
  onAbbrechen,
  onLoeschen,
}) => {
  const style = styles();
  const { t } = useTranslation();
  const [bearbeitungsModus, setBearbeitungsModus] = useState(false);
  const [loeschenDialogOffen, setLoeschenDialogOffen] = useState(false);

  const [ort, setOrt] = useState<string>("");
  const [datum, setDatum] = useState<Date>(new Date());
  const [telefon, setTelefon] = useState<string>("");
  const [teilnehmerListen, setTeilnehmerListen] = useState<TeilnehmerListe[]>(
    []
  );
  const [benachrichtigungsAdressen, setBenachrichtigungsAdressen] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (treffen) {
      setOrt(treffen.ort);
      setDatum(treffen.datum);
      setTelefon(treffen.meldeTelefon);
      setBenachrichtigungsAdressen(treffen.benachrichtigungsAdressen);
      setBearbeitungsModus(neu);
    }
  }, [treffen, neu]);

  if (!treffen) {
    return null;
  }
  if (!bearbeitungsModus) {
    const treffenBeschreibung: string =
      treffen.ort +
      t(" am ") +
      format(new Date(treffen.datum), "dd. MMMM yyyy", { locale: deLocale });
    return (
      <div className={style.beschreibung}>
        <div>{treffenBeschreibung}</div>
        <div>
          {t("Meldenummer: ")} {treffen.meldeTelefon}
        </div>
        <Button color="secondary" onClick={() => setBearbeitungsModus(true)}>
          <Edit />
        </Button>
      </div>
    );
  }
  const versteckeLoeschenKnopf =
    neu || treffen.meldungen.some((meldung) => !meldung.angekommen);

  const loeschenDialog = () => {
    return (
      <Dialog
        open={loeschenDialogOffen}
        maxWidth={"xs"}
        onClose={() => setLoeschenDialogOffen(false)}
      >
        <DialogTitle>{t("Bestätigung")}</DialogTitle>
        <DialogContent>
          {t(
            "Treffen vom " +
              format(new Date(treffen.datum), "dd. MMMM yyyy", {
                locale: deLocale,
              }) +
              " in " +
              treffen.ort +
              " wirklich löschen?"
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              onLoeschen();
              setLoeschenDialogOffen(false);
            }}
          >
            <Done />
          </Button>
          <Button
            color="secondary"
            onClick={() => setLoeschenDialogOffen(false)}
          >
            <Close />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const teilnehmerVorauswahl = () => {
    const listen = Object.keys(TeilnehmerListe).map((liste) => {
      const selektiert = teilnehmerListen.includes(liste as TeilnehmerListe);
      return (
        <Chip
          color={selektiert ? "primary" : "default"}
          onClick={(event) =>
            setTeilnehmerListen(
              selektiert
                ? teilnehmerListen.filter((l) => l !== liste)
                : [...teilnehmerListen, liste as TeilnehmerListe]
            )
          }
          label={liste}
        />
      );
    });
    return (
      <div>
        <TextField
          className={style.teilnehmer}
          label={t("Teilnehmervorauswahl")}
          fullWidth={false}
          disabled={false}
          inputMode="none"
          contentEditable={false}
          InputProps={{
            disabled: false,
            readOnly: true,
            unselectable: "on",
            startAdornment: (
              <InputAdornment position="start">{listen}</InputAdornment>
            ),
          }}
        />
      </div>
    );
  };

  return (
    <div className={style.reihe}>
      <div className={style.editor}>
        <TextField
          name="ort"
          label={t("Ort")}
          value={ort}
          onChange={(event) => setOrt(event.target.value)}
        />
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={deLocale}
        >
          <DatePicker
            renderInput={(props) => <TextField {...props} />}
            label={t("Datum")}
            disablePast
            value={new Date(datum)}
            onChange={(date) => {
              if (date) {
                setDatum(date);
              }
            }}
          />
        </LocalizationProvider>
        <TextField
          name="meldeTelefon"
          label={t("Meldetelefon")}
          value={telefon}
          onChange={(event) => setTelefon(event.target.value)}
          inputMode="tel"
        />
        {neu ? teilnehmerVorauswahl() : null}
        <MultiTextField
          wert={benachrichtigungsAdressen}
          onChange={setBenachrichtigungsAdressen}
          label={t("Benachrichtigungsadressen")}
          modus="email"
          vorschlaege={{ InReach: inReachMapping, "E-Mail": emailMapping }}
        />
        <Button
          color="primary"
          onClick={() => {
            const editTreffen: Treffen = {
              ...treffen,
              ort: ort,
              datum: datum,
              meldeTelefon: telefon,
              meldungen: teilnehmerListen
                .map((liste) => meldungsListe(getPersonen(liste)))
                .flat(1),
              benachrichtigungsAdressen: benachrichtigungsAdressen,
            };
            onSpeichern(editTreffen);
            setBearbeitungsModus(false);
          }}
        >
          <Done />
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            onAbbrechen();
            setBearbeitungsModus(false);
          }}
        >
          <Close />
        </Button>
      </div>
      {loeschenDialog()}
      {versteckeLoeschenKnopf ? null : (
        <Button
          color="secondary"
          className={style.loeschen}
          onClick={() => setLoeschenDialogOffen(true)}
        >
          <DeleteForever />
        </Button>
      )}
    </div>
  );
};
const styles = makeStyles((theme: Theme) =>
  createStyles({
    teilnehmer: {
      "& input": {
        width: "0px",
      },
    },
    beschreibung: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    reihe: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    editor: {
      display: "flex",
      alignItems: "center",
      "&& > div": {
        marginLeft: theme.spacing(1),
      },
    },
    loeschen: {
      cursor: "not-allowed",
    },
  })
);
